import { render, staticRenderFns } from "./AttachmentThumbnails.vue?vue&type=template&id=6b8729f0&scoped=true&"
import script from "./AttachmentThumbnails.vue?vue&type=script&lang=js&"
export * from "./AttachmentThumbnails.vue?vue&type=script&lang=js&"
import style0 from "./AttachmentThumbnails.vue?vue&type=style&index=0&id=6b8729f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8729f0",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BModal, BFormRow, BButton, BCard} from 'bootstrap-vue'
    installComponents(component, {BCol, BModal, BFormRow, BButton, BCard})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBModal, VBPopover} from 'bootstrap-vue'
    installDirectives(component, {'b-modal': VBModal, 'b-popover': VBPopover})
    

export default component.exports