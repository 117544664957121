<template>
  <div>
    <div v-if="files.length">
      <LoadingSpinner :is-loaded="false" />
      Nahráva sa {{ files[0].name }}
    </div>
    <div v-else>
      <h4>Potiahni sem novú prílohu</h4>
      <vue-upload-component
        ref="uploader"
        v-model="files"
        name="problem-attachment"
        :drop="true"
        @input="uploadAttachment"
      />
      <b-button variant="outline-dark">
        <label for="problem-attachment" class="m-0"> Vybrať prílohu </label>
      </b-button>
    </div>
    <div
      v-show="$refs.uploader && $refs.uploader.dropActive"
      class="drop-active"
    >
      <h3>Môžeš pustiť</h3>
    </div>
  </div>
</template>

<script>
import { LoadingSpinner } from "frontend-common";
import VueUploadComponent from "vue-upload-component";

export default {
  name: "AttachmentDropzone",
  components: {
    VueUploadComponent,
    LoadingSpinner,
  },
  mixins: [],
  props: {},
  data() {
    return {
      files: [],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    uploadAttachment(files) {
      const file = files[0];
      this.$emit("upload-attachment", file);
      setTimeout(() => (this.files = []), 2500);
    },
  },
};
</script>

<style scoped>
.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 3em;
  color: #fff;
  padding: 0;
}
</style>
