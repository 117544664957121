import { render, staticRenderFns } from "./ContentEditor.vue?vue&type=template&id=23cea640&scoped=true&"
import script from "./ContentEditor.vue?vue&type=script&lang=js&"
export * from "./ContentEditor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23cea640",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert, BCol, BButton, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BAlert, BCol, BButton, BRow, BContainer})
    

export default component.exports