<template>
  <b-row>
    <b-col md="6">
      <!-- The max-rows="10000" is there just to enable custom max-height. -->
      <b-form-group>
        <b-form-textarea
          ref="markdownInput"
          v-model="text"
          rows="10"
          max-rows="10000"
          debounce="400"
        />
      </b-form-group>
    </b-col>
    <b-col md="6">
      <div class="preview border bg-light px-3 py-2 mb-3">
        <vue-markdown :source="value" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import VueMarkdown from "vue-markdown";

export default {
  name: "MarkdownEditor",
  components: {
    VueMarkdown,
  },
  mixins: [],
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: "Načítavam...",
    };
  },
  computed: {},
  watch: {
    value() {
      this.text = this.value;
    },
    text() {
      this.$emit("input", this.text);
    },
  },
  mounted() {
    this.text = this.value;
  },
  methods: {
    save() {
      this.$emit("save", this.text);
    },
  },
};
</script>

<style scoped>
textarea {
  font-family: monospace;
  max-height: 60vh;
}

.preview {
  overflow-y: auto;
  max-height: 60vh;
}
</style>
