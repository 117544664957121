<template>
  <b-container fluid class="py-3">
    <b-alert :show="alert !== null" variant="warning">
      <font-awesome-icon class="mr-2" :icon="['fas', 'exclamation']" />
      <strong>{{ alert }}</strong>
    </b-alert>
    <b-row class="mb-2">
      <b-col v-if="title">
        <h2>{{ title }}</h2>
      </b-col>
      <b-col md="2">
        <b-button
          variant="outline-info"
          size="lg"
          block
          @click="$router.go(-1)"
        >
          <font-awesome-icon :icon="['fas', 'arrow-left']" /> Späť
        </b-button>
      </b-col>
      <b-col md="3">
        <b-button
          variant="outline-info"
          :disabled="saving"
          size="lg"
          block
          @click="save"
        >
          <LoadingSpinner :is-loaded="!saving">
            <font-awesome-icon :icon="['fas', 'save']" />
            Uložiť
          </LoadingSpinner>
        </b-button>
      </b-col>
    </b-row>
    <MarkdownEditor v-model="rawMarkdown" @save="save" />
    <slot />
    <AttachmentThumbnails
      :attachments="attachments"
      :attachments-loaded="attachmentsLoaded"
      @upload-attachment="uploadAttachment"
      @delete-attachment="deleteAttachment"
      @reload-attachments="reloadAttachments"
      @add-image="addImage"
    />
  </b-container>
</template>

<script>
import { LoadingSpinner } from "frontend-common";
import AttachmentThumbnails from "@/components/AttachmentThumbnails";
import MarkdownEditor from "@/components/utils/MarkdownEditor";

export default {
  name: "ContentEditor",
  components: {
    AttachmentThumbnails,
    MarkdownEditor,
    LoadingSpinner,
  },
  mixins: [],
  props: {
    title: {
      type: String,
      required: true,
    },
    modelId: {
      type: Number,
      required: true,
    },
    initial: {
      type: String,
      required: true,
    },
    alert: {
      type: String,
      required: false,
      default: null,
    },
    saving: {
      type: Boolean,
      required: false,
      default: false,
    },
    uploadFunction: {
      type: Function,
      required: true,
    },
    deleteFunction: {
      type: Function,
      required: true,
    },
    reloadFunction: {
      type: Function,
      required: true,
    },
    infoFunction: {
      type: Function,
      required: true,
    },
    saveFunction: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      rawMarkdown: "",
      attachments: [],
      attachmentsLoaded: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.rawMarkdown = this.initial;
  },
  methods: {
    addImage(text) {
      this.rawMarkdown += text;
    },
    save(newText) {
      if (this.saveFunction) {
        this.saveFunction(this.modelId, newText).then(() => {
          this.$root.successToast("Text úspešne uložený!");
        });
      } else {
        this.$emit("save-text", this.rawMarkdown);
      }
    },
    uploadAttachment(file) {
      this.uploadFunction(this.modelId, file).then(() => {
        this.$root.successToast("Príloha úspešne pridaná!");
        this.reloadAttachments();
      });
    },
    keepAttachementInMD(attachmentId) {
      const h = this.$createElement;

      return h("div", [
        h("p", [
          "Chcete zmazať aj príslušný text z markdownu? Vyzerá nejako takto:",
        ]),
        h("pre", { class: "text-left mx-5" }, [
          '<div style="text-align: center">',
          h("br"),
          '    <img alt="Obrazok" src="...',
          `/problems/${this.modelId}/attachments/${attachmentId}/" />`,
          h("br"),
          "</div> ",
        ]),
      ]);
    },
    async deleteAttachment(attachmentId) {
      await this.deleteFunction(this.modelId, attachmentId);

      this.$root.infoToast("Príloha vymazaná");
      this.reloadAttachments();

      const regex = new RegExp(
        '<div .*?>\\s+<img.*?src=".*?\\/problems\\/' +
          `${this.modelId}\\/attachments\\/${attachmentId}\\/".*?>\\s+<\\/div>`,
        "g",
      );

      const occurance = this.rawMarkdown.match(regex).length > 0;

      const removeFromMarkdown =
        occurance &&
        (await this.$root.infoModalConfirm(
          "Zmazať prílohu aj z markdownu?",
          this.keepAttachementInMD(attachmentId),
          "lg",
          "default-modal-id",
          {
            okTitle: "Áno",
            cancelTitle: "Nie",
          },
        ));

      if (removeFromMarkdown) {
        // Replace all occurences of give attachment
        this.rawMarkdown = this.rawMarkdown.replace(regex, "");
      } else {
        // If the user doesn't want to remove the attachment
        // add space to the end, to cause rerender
        this.rawMarkdown += " ";
      }
    },
    reloadAttachments() {
      this.attachmentsLoaded = false;
      this.reloadFunction(this.modelId)
        .then((response) => {
          this.attachments = response.map((attachment) => ({
            ...attachment,
            dimensions: "",
            size: "",
            contentType: "",
          }));
          this.refreshInfo();
        })
        .finally(() => (this.attachmentsLoaded = true));
    },
    async refreshInfo() {
      // Image information is loaded asynchronously to enhance the experience.
      // Also in practice, the image elements tend to
      // not be ready when you access their dims instantly.
      for (const att of this.attachments) {
        // We do this sequentially on purpose, doing it in parallel tends to throw errors.
        await this.infoFunction(this.modelId, att.id).then((response) => {
          att.size = Math.round(response.headers["content-length"] / 1000);
          att.contentType = response.headers["content-type"];
        });
      }
      for (const attachment of this.attachments) {
        attachment.dimensions = this.getDimensions(attachment.id);
      }
    },
    getDimensions(id) {
      const card = this.$refs[`card-${id}`];
      if (!card || !card[0]) return "";
      const img = card[0].children[0];
      const dims = `${img.naturalWidth}x${img.naturalHeight}`;
      return dims === "0x0" ? "" : dims;
    },
  },
};
</script>

<style scoped></style>
