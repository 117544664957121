<template>
  <b-form-row>
    <b-col class="text-center" cols="12">
      <b-form-row>
        <b-col md="6">
          <AttachmentDropzone
            class="my-3"
            @upload-attachment="uploadAttachment"
            @reload-attachments="reloadAttachments"
          />
        </b-col>
        <b-col md="6">
          Tipy:
          <div>
            <a v-b-modal.hint-types class="link">
              <font-awesome-icon :icon="['fas', 'question']" />
              Aké obrázky môžem pridávať
            </a>
          </div>
          <b-modal id="hint-types" ok-only title="Aké obrázky môžem pridávať">
            <p>
              Povolené formáty obrázkov sú <code>.jpg</code>, <code>.png</code>,
              <code>.webp</code> a <code>.gif</code>.
            </p>
            <p>
              Ako sa hovorí, pamäť sú peniaze, takže nemôžeme používať príliš
              veľké obrázky. Ideálna veľkosť je menej ako {{ largeLimit }} kB.
              Ak je Tvoj obrázok väčší, uvidíš varovanie a obrázky väčšie ako
              {{ tooLargeLimit }} kB nie je možné použiť. Ak chceš použiť veľký
              obrázok, pozri tip o zmenšovaní (kompresii).
            </p>
          </b-modal>
          <div>
            <a v-b-modal.hint-style class="link">
              <font-awesome-icon :icon="['fas', 'question']" />
              Ako zmeniť veľkosť obrázku (ako sa zobrazuje)
            </a>
          </div>
          <b-modal
            id="hint-style"
            ok-only
            title="Návod na zmenu veľkosti obrázku"
          >
            <p>
              Ak chceš zmeniť veľkosť obrázku (napr. na 400 px), zmeň v kóde
              <code>&lt;img</code> na
              <code class="text-nowrap">&lt;img style="width: 400px"</code>.
            </p>
            <p>
              Podobne funguje aj <code>height</code>. Ak žiaden rozmer
              neuvedieš, bude na stránke tak veľký ako pôvodný súbor. Odporúčame
              šírku okolo 400 až 500 px.
            </p>
          </b-modal>
          <div>
            <a v-b-modal.hint-compress class="link">
              <font-awesome-icon :icon="['fas', 'question']" /> Ako zmenšiť
              obrázok (kompresia)
            </a>
          </div>
          <b-modal
            id="hint-compress"
            title="Návod na kompresiu obrázkov"
            size="lg"
            ok-only
          >
            <div>
              <p>
                Obrázky do zadaní a vzorákov by mali mať veľkosť do
                {{ largeLimit }} kB. Obrázky väčšie ako {{ tooLargeLimit }} kB
                sa už do textu pridať nedajú.
              </p>
              <div>
                Ak je tvoj obrázok príliš veľký, môžeš ho skomprimovať použitím
                <a target="_blank" href="https://squoosh.app/"
                  >aplikácie squoosh</a
                >. Postup:
                <ul>
                  <li>
                    Nahraj obrázok na
                    <a target="_blank" href="https://squoosh.app/">squoosh.</a>
                  </li>
                  <li>
                    V sekci <strong>Compress</strong> zvoľ formát
                    <strong>WebP</strong>
                    (miesto MozJPEG) a čo najvyšší effort.
                  </li>
                  <li>
                    Kvalitu potom zvoľ čo najmenšiu, ale tak, aby obrázok stále
                    vyzeral dobre.
                  </li>
                  <li>
                    Ak stále nie je menší ako {{ largeLimit }} kB, pohraj sa s
                    nastaveniami.
                  </li>
                  <li>
                    Skús použiť aj <strong>Resize</strong> (väčší rozmer
                    väčšinou stačí do 1000 px).
                  </li>
                  <li>
                    Obrázok stiahni a nahraj ho sem (ten príliš veľký zase
                    vymaž).
                  </li>
                </ul>
                Je možné zvoliť aj iný formát, ale vačinou je
                <code>.webp</code> lepší ako <code>.jpeg</code> a ten je lepší
                ako <code>.png</code>.
              </div>
              <p>
                Ak máš priveľké GIF-ko, skús pogoogliť nejaký nástroj. Pri SVG
                by kompresia nemala byť potrebná.
              </p>
            </div>
          </b-modal>
        </b-col>
      </b-form-row>
    </b-col>
    <b-col v-if="!attachmentsLoaded" key="attachments-loading">
      <LoadingSpinner :is-loaded="false" />
    </b-col>
    <template v-else>
      <b-col
        v-for="attachment of attachments"
        :key="'attachment-' + attachment.id"
        sm="6"
        md="4"
        xl="2"
      >
        <b-card
          :ref="`card-${attachment.id}`"
          class="mb-3 text-center"
          :img-src="`${urlPrefix}${attachment.attachment}`"
          :footer-bg-variant="colorVariant(attachment.size, 'light')"
          footer-class="p-1"
          img-top
        >
          <div
            v-if="attachment.size > tooLargeLimit"
            class="text-danger font-weight-bold"
          >
            POZOR
          </div>
          <div
            v-if="attachment.size > largeLimit && attachment.size"
            class="small"
          >
            Obrázok je
            {{ tooLargeLimit > attachment.size ? "" : "príliš" }} veľký (>
            {{ tooLargeLimit > attachment.size ? largeLimit : tooLargeLimit }}
            kB).
            {{
              attachment.size > tooLargeLimit
                ? `Skús ho zmenšiť na ${largeLimit}kB.`
                : `Zváž jeho zmenšenie.`
            }}
          </div>
          <b-button
            v-if="attachment.size > largeLimit"
            v-b-modal.hint-compress
            block
            :variant="colorVariant(attachment.size)"
            size="sm"
            class="mt-2"
          >
            <font-awesome-icon :icon="['fas', 'question']" />
            Ako zmenšiť obrázok
          </b-button>
          <div
            v-b-popover.hover.top="
              attachment.size > tooLargeLimit ? 'Najprv obrázok zmenši' : ''
            "
          >
            <b-button
              :disabled="attachment.size >= tooLargeLimit"
              block
              variant="outline-dark"
              size="sm"
              class="mt-2"
              @click="addImage(attachment.attachment)"
            >
              Pridať
            </b-button>
          </div>
          <b-button
            block
            variant="outline-dark"
            size="sm"
            class="mt-2"
            @click="deleteImage(attachment)"
          >
            Zmazať
          </b-button>
          <template #footer>
            <div>
              {{ attachment.size }} kB ({{
                prettyType(attachment.contentType)
              }})
              <span class="small">{{ attachment.dimensions }}</span>
            </div>
          </template>
        </b-card>
      </b-col>
    </template>
  </b-form-row>
</template>

<script>
import { LoadingSpinner } from "frontend-common";
import AttachmentDropzone from "@/components/AttachmentDropzone";
import constants from "@/constants";

export default {
  name: "AttachmentThumbnails",
  components: {
    AttachmentDropzone,
    LoadingSpinner,
  },
  mixins: [],
  props: {
    attachments: {
      type: Array,
      required: true,
    },
    attachmentsLoaded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      files: [],
      urlPrefix: constants.backendUrl,
      largeLimit: constants.pictureSizeLimits.large,
      tooLargeLimit: constants.pictureSizeLimits.tooLarge,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$emit("reload-attachments");
  },
  methods: {
    uploadAttachment(file) {
      this.$emit("upload-attachment", file);
    },
    reloadAttachments() {
      this.$emit("reload-attachments");
    },
    addImage(link) {
      this.$emit(
        "add-image",
        '\n\n<div style="text-align: center">\n' +
          `    <img alt="Obrazok" src="${this.urlPrefix}${link}" />\n</div>`,
      );
    },
    deleteImage(attachment) {
      this.$emit("delete-attachment", attachment.id);
    },
    colorVariant(size, defaultColor = "dark") {
      if (size >= this.tooLargeLimit) return "danger";
      if (size >= this.largeLimit) return "warning";
      return defaultColor;
    },
    prettyType(type) {
      switch (type) {
        case "image/svg+xml":
          return "svg";
        case "image/png":
          return "png";
        case "image/jpeg":
          return "jpeg";
        case "image/gif":
          return "gif";
        case "application/octet-stream":
          return "webp";
        default:
          return type;
      }
    },
  },
};
</script>

<style scoped>
#hint-compress a {
  color: var(--secondary);
}
</style>
