import { render, staticRenderFns } from "./MarkdownEditor.vue?vue&type=template&id=160ff5ba&scoped=true&"
import script from "./MarkdownEditor.vue?vue&type=script&lang=js&"
export * from "./MarkdownEditor.vue?vue&type=script&lang=js&"
import style0 from "./MarkdownEditor.vue?vue&type=style&index=0&id=160ff5ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160ff5ba",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend-internal/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormTextarea, BFormGroup, BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BFormTextarea, BFormGroup, BCol, BRow})
    

export default component.exports